import { Footer } from "./components";
import * as imgs from "imgs";
import Spiral from "components/Spiral";
import Anchor from "components/Anchor";

import { details, faq, courses, plans, testimonials } from "data";
import styled from "styled-components";
import { useState } from "react";

export const Heading = ({
  children,
  size = "large",
  ...props
}: { size?: "small" | "medium" | "large" } & JSX.IntrinsicElements["h2"]) => {
  const sizes = {
    small: "text-lg lg:text-xl xl:text-2xl font-normal",
    medium: "text-xl lg:text-2xl xl:text-4xl font-extrabold",
    large: "text-2xl lg:text-4xl xl:text-7xl font-extrabold",
  };

  return (
    <h2
      data-before={children}
      className={`
      ${sizes[size] || sizes.large}
      text-transparent bg-clip-text 
      cursor-default
      bg-gradient-to-r
    from-[#4776E6]
    to-[#42EBD5]
      transition-all
      hover:bg-transparent
      relative
      z-10
      hover:text-transparent
      group
       ${props.className || ""}`}
    >
      <div
        className="opacity-0 
        bg-clip-text
      absolute
      left-0
      top-0
      w-full
      h-full
      bg-gradient-to-r
      to-[#4776E6]
      from-[#ca13d7]
      z-[-1]
      transition-all
      duration-300
      hover:before:opacity-100
      before:content-[attr(data-before)]
      group-hover:opacity-100
      "
      >
        {children}
      </div>
      {children}
    </h2>
  );
};

const Paragraph = ({ children, ...props }: JSX.IntrinsicElements["p"]) => {
  return (
    <p
      className={`font-normal text-lg leading-normal lg:text-xl xl:leading-snug text-white ${props.className}`}
    >
      {children}
    </p>
  );
};

// const WrapperAlberto = styled.div`
//   background: url(${imgs.albertoProfessor}) #101642 top right no-repeat;
//   background-size: auto 100%;

//   @media (max-width: 1023px) {
//     background-image: none;
//   }
// `;

const Faq = styled.div`
  background: url(${imgs.Faq}) #161e5d top right no-repeat;
  background-size: cover;
`;

function Home() {
  const [faqOpen, setFaqOpen] = useState<number[]>([]);

  return (
    <div className="bg-[#0F0B30] relative">
      <div className="relative overflow-hidden">
        <div className="container relative z-20 px-4 lg:px-14">
          <div className="grid grid-cols-12 relative z-20">
            <div className="col-span-10 col-start-2">
              <div className="grid grid-cols-12">
                <div className="col-span-12 lg:col-span-6 pb-10 lg:pb-20">
                  <img
                    src={imgs.logoWhite}
                    alt=""
                    className="w-2/3 lg:w-auto lg:my-10"
                  />

                  <div className="flex flex-col gap-6">
                    <Heading>
                      Faça parte da maior escola de hipnose do mundo!
                    </Heading>

                    <Paragraph>
                      Faça parte da nossa comunidade, acesse nosso portal de
                      conteúdos científicos e atualizados sobre auto-hipnose,
                      induções hipnóticas, aplicações especiais e psicoterapia.
                    </Paragraph>

                    <div>
                      <Anchor href="#plans" className="mt-4">
                        Quero começar agora
                      </Anchor>
                    </div>
                  </div>
                </div>

                <div className="col-span-12 lg:col-span-6 lg:mt-14">
                  <div className="flex items-end h-full relative">
                    <img src={imgs.alberto} alt="" className="" />

                    <div className="absolute -z-10 top-0 left-0 w-full h-full rounded-3xl bg-violet-900 blur-[70px] opacity-40"></div>

                    <div className="absolute z-10 -ml-24 inset-auto w-1/2 h-1/2 rounded-3xl bg-violet-900 blur-[80px] opacity-30"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="absolute z-10 w-full h-full top-0 bottom-0">
          <Spiral />
        </div>
      </div>

      <div className="bg-[#0F0B30] py-16 lg:py-36">
        <div className="container px-4 lg:px-14">
          <Heading className="w-full lg:w-2/3 text-center mx-auto">
            Ser um profissional da saúde atualizado não precisa mais ser um
            desafio
          </Heading>

          <div className="grid grid-cols-12 mt-10 lg:mt-32">
            <div className="col-span-12 xl:col-span-10 xl:col-start-2 gap-8">
              <div className="flex flex-col gap-10 lg:gap-20">
                <div className="grid grid-cols-12 lg:gap-10 xl:gap-20">
                  <div className="col-span-12 lg:col-span-6 order-last lg:order-first">
                    <div className="flex flex-col gap-4">
                      <Paragraph>
                        Hoje em dia, mais do que nunca, se você quer estar
                        sempre atualizado com o mercado, você precisa de duas
                        coisas muito importantes: boas fontes, científicas e
                        confiáveis, e tempo.
                      </Paragraph>

                      <Paragraph>
                        Mas você, como profissional de saúde, conhece o desafio
                        de ter ambos.
                      </Paragraph>

                      <Paragraph>
                        E agora você me diz: o que vale a pena? Gastar horas e
                        horas pesquisando uma boa fonte, mas não ter tempo para
                        se aprofundar e realmente dominar a nova técnica em
                        questão…
                      </Paragraph>
                    </div>
                  </div>

                  <div className="col-span-12 lg:col-span-6 order-1">
                    <div className="relative flex h-full items-center">
                      <imgs.Img />
                    </div>
                  </div>
                </div>

                <div className="grid grid-cols-12 lg:gap-10 xl:gap-20">
                  <div className="col-span-12 lg:col-span-6">
                    <div className="relative flex h-full items-center">
                      <imgs.Reading />
                    </div>
                  </div>

                  <div className="col-span-12 lg:col-span-6">
                    <div className="text-white flex flex-col gap-4">
                      <Paragraph>
                        Ou estudar a fundo dada ferramenta, sem saber se pode
                        confiar no material que você está lendo ou assistindo?
                      </Paragraph>

                      <Paragraph>
                        A verdade é que nenhuma dessas alternativas vale a pena.
                      </Paragraph>

                      <Paragraph>
                        Não adianta ficar horas pesquisando e lendo conteúdos
                        sem fundamentação e que não passam de pseudociência,
                        você estaria apenas perdendo tempo. E na “era das fake
                        news”, materiais de qualidade são fundamentais, mas
                        passar horas procurando por isso também não precisa mais
                        ser sua realidade.
                      </Paragraph>

                      <Paragraph className="text-2xl leading-tight">
                        Não quando você é um membro do Hypnosis Lab, pelo menos.
                      </Paragraph>
                    </div>
                  </div>
                </div>

                <div className="grid grid-cols-12 lg:gap-10 xl:gap-20">
                  <div className="col-span-12 lg:col-span-6 order-last lg:order-first">
                    <div className="text-white flex flex-col gap-4">
                      <Paragraph>
                        Hoje em dia, mais do que nunca, se você quer estar
                        sempre atualizado com o mercado, você precisa de duas
                        coisas muito importantes: boas fontes, científicas e
                        confiáveis, e tempo.
                      </Paragraph>
                      <Paragraph>
                        Mas você, como profissional de saúde, conhece o desafio
                        de ter ambos.
                      </Paragraph>

                      <Paragraph>
                        E agora você me diz: o que vale a pena? Gastar horas e
                        horas pesquisando uma boa fonte, mas não ter tempo para
                        se aprofundar e realmente dominar a nova técnica em
                        questão…
                      </Paragraph>
                    </div>
                  </div>

                  <div className="col-span-12 lg:col-span-6 mb-10 lg:mb-0">
                    <div className="relative flex h-full items-center">
                      <imgs.VideoFiles />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-center my-20 animate-bounce">
            <Anchor href="#plans">Quero entrar para o Hypnosis Lab</Anchor>
          </div>

          <div className="grid grid-cols-12">
            <div className="col-span-12 xl:col-span-10 xl:col-start-2 cursor-default">
              <div className="grid grid-cols-12 gap-6 lg:gap-10">
                <div className="col-span-12 lg:col-span-4 h-full bg-gradient-to-r from-[#4776E6] to-[#42EBD5] p-1 rounded-5xl transition-all hover:shadow-xl hover:scale-110">
                  <div className="flex flex-col h-full gap-4 bg-[#1F1B42] pt-10 lg:pt-20 pb-10 px-10 rounded-5xl hover:bg-transparent transition-all">
                    <imgs.Teacher />
                    <Paragraph>
                      Aulas atualizadas constantemente e acesso a 10 formações
                      por ano
                    </Paragraph>
                  </div>
                </div>
                <div className="col-span-12 lg:col-span-4 h-full bg-gradient-to-r from-[#4776E6] to-[#42EBD5] p-1 rounded-5xl transition-all hover:shadow-xl hover:scale-110">
                  <div className="flex flex-col h-full gap-4 bg-[#1F1B42] pt-10 lg:pt-20 pb-10 px-10 rounded-5xl hover:bg-transparent transition-all">
                    <imgs.Globe />
                    <Paragraph>
                      As novidades e técnicas mais avançadas do mundo reunidas
                      em um só lugar
                    </Paragraph>
                  </div>
                </div>
                <div className="col-span-12 lg:col-span-4 h-full bg-gradient-to-r from-[#4776E6] to-[#42EBD5] p-1 rounded-5xl transition-all hover:shadow-xl hover:scale-110">
                  <div className="flex flex-col h-full gap-4 bg-[#1F1B42] pt-10 lg:pt-20 pb-10 px-10 rounded-5xl hover:bg-transparent transition-all">
                    <imgs.Play />
                    <Paragraph>
                      Conteúdo em suas mãos: praticidade e qualidade
                    </Paragraph>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-[#101642]">
        <div className="container px-4 lg:px-14 py-10 lg:py-20 xl:py-36">
          <div className="flex flex-col gap-10 lg:w-1/2 mx-auto text-center">
            <Heading>
              Mas afinal, por que você precisa usar a Hipnose no seu consultório
              hoje?
            </Heading>

            <Paragraph>
              A Hipnose, além de ser totalmente científica e uma ferramenta sem
              contraindicações, é aprovada pela OMS e recomendada pela maioria
              dos Conselhos Federais da área da Saúde no Brasil:
            </Paragraph>
          </div>
        </div>
      </div>

      <div className="bg-white flex">
        <div className="container py-4 lg:py-10 px-4 lg:px-14">
          <div className="grid grid-cols-12">
            <div className="col-span-12 lg:col-span-10 lg:col-start-2">
              <div className="grid grid-cols-12">
                <div className="col-span-12">
                  <div className="flex gap-4">
                    <imgs.Cons01 className="h-20" />
                    <imgs.Cons02 className="h-20" />
                    <imgs.Cons03 className="h-20" />
                    <imgs.Cons04 className="h-20" />
                    <imgs.Cons05 className="h-20" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-[#101642]">
        <div className="container px-4 lg:px-14 py-10 lg:py-28">
          <div className="grid grid-cols-12">
            <div className="col-span-12 xl:col-span-10 xl:col-start-2">
              <div className="grid grid-cols-12 lg:gap-10 items-center">
                <div className="col-span-12 lg:col-span-6 order-last lg:order-first">
                  <div className="flex flex-col gap-8">
                    <Paragraph>
                      Ela pode ser usada associada a diversas outras técnicas no
                      tratamento de transtornos mentais, vícios, dores crônicas,
                      reabilitação de pacientes e até mesmo em cirurgias.
                    </Paragraph>

                    <Paragraph>
                      Ter essa técnica na sua caixa de ferramentas pode ser a
                      diferença entre o sucesso e o fracasso do seu consultório.
                      Ela pode ser a chave que faltava para aquele paciente que
                      está quase desistindo do tratamento.
                    </Paragraph>

                    <Paragraph>
                      E mais do que isso, ela é a solução para que você não caia
                      na temida falência terapêutica, que é aquele momento em
                      suas técnicas já não são suficientes, você começa a perder
                      pacientes e percebe que seu atendimento já não é o mesmo…
                    </Paragraph>
                  </div>
                </div>

                <div className="col-span-12 lg:col-span-6">
                  <imgs.Frame />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-[#0F0B30] py-20">
        <div className="container px-4 lg:px-14">
          <div className="grid grid-cols-12">
            <div className="col-span-12 xl:col-span-10 xl:col-start-2">
              <div className="grid grid-cols-12 items-center gap-4 lg:gap-10">
                <div className="col-span-12 xl:col-span-5">
                  <Paragraph className="text-3xl leading-normal">
                    A Hipnose já está no mercado, olhe ao redor e perceba que a
                    hora de avançar chegou e se não for agora, você ficará para
                    trás e ultrapassado.
                  </Paragraph>
                </div>

                <div className="col-span-12 lg:col-span-6">
                  <div className="flex gap-8 flex-col lg:flex-row">
                    <img src={imgs.Media1} alt="" className="max-w-full" />
                    <img src={imgs.Media2} alt="" className="max-w-full" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-[#101642] pt-20 relative overflow-hidden">
        <div className="container px-4 lg:px-14 relative z-10">
          <div className="absolute z-0 -bottom-10 -left-3/4 lg:-left-1/3">
            <imgs.Lines />
          </div>

          <div className="grid grid-cols-12 relative">
            <div className="col-span-12 xl:col-span-10 xl:col-start-2">
              <div className="grid grid-cols-12 gap-4 lg:gap-10">
                <div className="col-span-12 lg:col-span-6 order-last lg:order-first">
                  <div className="h-full flex items-end">
                    <img src={imgs.alberto2} />
                  </div>
                </div>

                <div className="col-span-12 lg:col-span-6">
                  <div className="flex flex-col gap-4 h-full justify-center pb-10 lg:pb-20">
                    <Paragraph className="text-xl xl:text-3xl leading-tight">
                      Se você está aqui e já percebeu tudo isso, você já pode
                      começar seus estudos, mas, caro colega, se você…
                    </Paragraph>

                    <Paragraph>
                      é alguém que tem medo de experimentar novas abordagens e
                      sair da zona de conforto;
                    </Paragraph>

                    <Paragraph>
                      alguém que está estagnado no tempo e não percebe que
                      precisa se atualizar;
                    </Paragraph>

                    <Paragraph>
                      ou alguém que não quer entregar mais qualidade de vida aos
                      seus pacientes…
                    </Paragraph>

                    <Paragraph className="text-lg xl:text-xl leading-tight">
                      Seu lugar não é aqui e você pode abandonar esta página.
                    </Paragraph>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gradient-to-r from-[#4776E6] to-[#42EBD5] h-[4px]"></div>

      <div className="bg-[#101642] py-10 xl:py-14">
        <div className="container px-4 lg:px-14">
          <div className="grid grid-cols-12">
            <div className="col-span-12 xl:col-span-10 xl:col-start-2">
              <div className="grid grid-cols-12">
                <div className="col-span-12">
                  <div className="flex flex-col lg:flex-row justify-center items-center gap-10 lg:gap-20">
                    <div className="flex flex-col lg:flex-row flex-grow-1 gap-4 lg:gap-10 items-center">
                      <Heading className="text-white" size="medium">
                        A hora de decidir é agora
                      </Heading>

                      <div className="rotate-90 lg:rotate-0">
                        <imgs.ArrowRight />
                      </div>
                    </div>

                    <div className="flex flex-shrink-0">
                      <Anchor href="#plans">GARANTIR MEU LUGAR</Anchor>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-[#0F0B30] py-20">
        <div className="flex flex-col gap-6">
          <div className="px-4 lg:w-1/3 mx-auto text-center">
            <Heading>O que você vai encontrar aqui?</Heading>
          </div>

          <div className="px-4 lg:w-1/3 mx-auto text-center">
            <Paragraph>
              Conheça agora alguns dos muitos cursos aos quais você terá acesso:
            </Paragraph>
          </div>
        </div>

        <div className="container px-4 lg:px-14 mt-10 lg:mt-20">
          <div className="grid grid-cols-12">
            <div className="col-span-12 xl:col-span-10 xl:col-start-2">
              <div className="grid grid-cols-12 gap-4 lg:gap-8">
                {courses.map((item, index) => (
                  <div className="col-span-6 lg:col-span-3" key={index}>
                    <div className="flex h-full relative rounded-lg overflow-hidden group">
                      <div className="relative w-full">
                        <img
                          src={item.image}
                          className="w-full h-full object-fit object-center"
                        />

                        <div
                          className="
                          absolute top-0 l-0 w-full h-full
                          transition-all
                          bg-gradient-to-b
                        to-[#4776E6]/[.6]
                        from-[#85088d]/[.6]
                          opacity-0
                          group-hover:opacity-100"
                        ></div>
                      </div>

                      <div
                        className="absolute top-0 l-0 w-full h-full p-4 flex flex-col gap-1 justify-end items-start 
                      
                       "
                      >
                        {item.type === "Curso" ? (
                          <div className="bg-[rgba(29,29,29,0.5)] text-[#0FEFFD] flex px-2 py-1 rounded-bl-lg rounded-tr-lg">
                            {item.type}
                          </div>
                        ) : (
                          <div className="bg-[rgba(29,29,29,0.5)] text-[#F3D1FF] flex px-2 py-1 rounded-bl-lg rounded-tr-lg">
                            {item.type}
                          </div>
                        )}

                        <imgs.Rating />

                        <Paragraph>{item.name}</Paragraph>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="px-4 lg:w-1/3 mx-auto text-center mt-20">
          <Paragraph>
            E muito mais! Serão dez treinamentos completos e a chance de se
            aprofundar cada vez mais na ferramenta da Hipnose, se capacitando
            ainda mais e proporcionando tratamentos mais precisos e eficazes.
          </Paragraph>
        </div>

        <div className="container px-4 lg:px-14 mt-10 lg:mt-20">
          <div className="grid grid-cols-12">
            <div className="col-span-12 xl:col-span-10 xl:col-start-2">
              <div className="grid grid-cols-12 gap-4 lg:gap-10">
                {details.map((item, index) => (
                  <div
                    className="col-span-12 lg:col-span-4 text-center"
                    key={index}
                  >
                    <div className="bg-[#101642] px-6 py-8 rounded-2xl h-full flex flex-col gap-2 transition-all hover:shadow-2xl">
                      <img src={item.image} className="mx-auto" />

                      <Heading>{item.name}</Heading>

                      <Paragraph className="text-xl leading-tight">
                        {item.description}
                      </Paragraph>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-10 lg:pt-40 relative">
        <div className="container px-4 lg:px-14 pb-10 xl:pb-40">
          <div className="grid grid-cols-12">
            <div className="col-span-12 xl:col-span-10 xl:col-start-2">
              <div className="grid grid-cols-12">
                <div className="col-span-12 lg:col-span-5">
                  <div className="flex flex-col gap-4">
                    <p className="text-xl lg:text-3xl leading-tight font-extrabold text-white">
                      Conheça seu professor
                    </p>

                    <Heading className="text-3xl">
                      Alberto <br className="hidden lg:block" /> Dell’Isola
                    </Heading>

                    <div className="flex flex-col gap-4">
                      <Paragraph>
                        Escritor, Palestrante, Professor de Psicologia e de
                        Hipnose. Graduado em Psicologia (2009) e Mestre em
                        Psicologia Jurídica (2014), ambos pela UFMG. Autor do
                        best-seller “Mentes Brilhantes”.
                      </Paragraph>

                      <Paragraph>
                        Ensinou hipnose clínica baseada em evidências a mais de
                        100.000 pessoas presencialmente e através da internet,
                        sendo considerado a maior autoridade no Brasil sobre o
                        tema.
                      </Paragraph>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="relative lg:absolute bottom-0 right-0 w-full h-full flex flex-col">
          <img
            src={imgs.albertoProfessor}
            alt=""
            className="relative z-10 w-4/5 ml-auto lg:w-auto lg:h-[90%] mt-auto"
          />
          <div className="animate-pulse absolute top-0 left-0 bottom-0 right-0 w-full overflow-hidden">
            <imgs.vector className="w-full h-full lg:w-auto lg:h-auto ml-auto" />
          </div>
        </div>
      </div>

      <div className="bg-[#101642] flex flex-col gap-10 lg:gap-20 py-10 lg:py-24">
        <div className="px-4 lg:w-1/2 mx-auto text-center">
          <Heading>Eles já aprenderam comigo e agora é a sua vez!</Heading>
        </div>

        <div className="container px-4 lg:px-14">
          <div className="grid grid-cols-12">
            <div className="col-span-12 xl:col-span-10 xl:col-start-2 cursor-default">
              <div className="grid grid-cols-12 gap-4 lg:gap-10">
                {testimonials.map((testimonial) => (
                  <div
                    key={testimonial.name}
                    className="col-span-12 lg:col-span-4 h-full bg-gradient-to-r from-[#4776E6] to-[#42EBD5] p-[1px] rounded-5xl transition-all hover:shadow-2xl hover:scale-110"
                  >
                    <div className="flex flex-col h-full gap-4 bg-[#101642] py-6 px-6 lg:py-10 lg:px-10 rounded-5xl">
                      <div className="flex flex-col gap-4 flex-grow">
                        <imgs.quote />
                        <Paragraph>{testimonial.text}</Paragraph>
                      </div>

                      <div>
                        <Paragraph className="text-base">
                          {testimonial.name}
                        </Paragraph>
                        <Paragraph className="text-sm">
                          {testimonial.occupation}
                        </Paragraph>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="bg-[#0F0B30] flex flex-col gap-10 lg:gap-20 py-10 lg:py-24"
        id="plans"
      >
        <div className="px-4 lg:w-1/2 mx-auto text-center">
          <Heading size="medium">
            Dedique seu tempo ao que realmente vai trazer resultados concretos
            aos seus pacientes
          </Heading>
        </div>

        <div className="container px-4 lg:px-14">
          <div className="grid grid-cols-12">
            <div className="col-span-12 xl:col-span-10 xl:col-start-2 cursor-default">
              <div className="sm:w-auto md:w-[400px] lg:w-[500px] mx-auto">
                {plans.map((plan) => (
                  <div
                    key={plan.name}
                    className={`col-span-12 lg:col-span-6 h-full bg-gradient-to-r from-[#4776E6] to-[#42EBD5] p-[1px] rounded-5xl shadow-xl transition-all hover:shadow-2xl ${
                      plan.name === "MENSAL" ? "scale-90" : ""
                    }`}
                  >
                    <div className="flex flex-col h-full gap-7 xl:gap-10 bg-[#101642] py-6 lg:py-10 px-10 lg:px-20 rounded-5xl">
                      <Heading size="medium" className="">
                        <span className="text-xl font-bold">PLANO</span>{" "}
                        {plan.name}
                      </Heading>

                      <div className="flex flex-col gap-4">
                        <imgs.Divider />
                        <h4 className="text-white text-8xl font-black">
                          <span className="font-normal text-xl">12x de</span>{" "}
                          {plan.price}
                        </h4>
                        <h5 className="text-[#42EBD5] text-xl font-normal">
                          {plan.fullPrice}
                        </h5>
                        <imgs.Divider />
                      </div>

                      <div className="flex flex-col gap-4">
                        {plan.benefits.map((benefit, index) => (
                          <div className="flex items-center gap-4" key={index}>
                            <imgs.Check />
                            <Paragraph>{benefit}</Paragraph>
                          </div>
                        ))}
                      </div>

                      <div className="text-center">
                        <Anchor
                          href={plan.link}
                          target="_blank"
                          size="medium"
                          variant={
                            plan.name === "MENSAL" ? "primary" : "secondary"
                          }
                        >
                          Inscrever-se Agora
                        </Anchor>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="grid grid-cols-12">
            <div className="col-span-12 xl:col-span-10 xl:col-start-2">
              <div className="grid grid-cols-12">
                <div className="col-span-12 items-center justify-center text-center content-center">
                  <div className="inline-flex flex-col lg:flex-row justify-center items-center mt-10 gap-4 lg:gap-10">
                    <Heading size="small" className="text-white">
                      Pagamento 100% Seguro
                    </Heading>
                    <imgs.PayLogos />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Faq className="flex flex-col gap-10 py-10 lg:py-24">
        <div className="px-4 lg:w-1/2 mx-auto text-center">
          <Heading>Perguntas Frequentes</Heading>
        </div>

        <div className="container px-4 lg:px-14">
          <div className="grid grid-cols-12">
            <div className="col-span-12 lg:col-span-10 lg:col-start-2">
              <div className="grid grid-cols-12">
                <div className="col-span-12">
                  <div className="flex flex-col gap-4">
                    {faq.map((item, index) => (
                      <div
                        key={index}
                        onClick={() =>
                          setFaqOpen((s) => {
                            const open = [...s];
                            if (open.includes(index))
                              return open.filter((i) => i !== index);

                            open.push(index);
                            return open;
                          })
                        }
                        className="text-start py-4 group cursor-pointer flex flex-col gap-4"
                      >
                        <div className="flex gap-4">
                          <div className="flex-grow">
                            <Heading
                              size="small"
                              className={`font-semibold ${
                                faqOpen.includes(index)
                                  ? "text-[#42EBD5]"
                                  : "text-white"
                              }`}
                            >
                              {item.question}
                            </Heading>
                          </div>

                          <div className="flex-shrink-0 scale-75 lg:scale-100">
                            <imgs.Plus />
                          </div>
                        </div>

                        {faqOpen.includes(index) && (
                          <div className="animate-fade">
                            <Paragraph>{item.answer}</Paragraph>
                          </div>
                        )}

                        <div className="bg-gradient-to-r from-[#4776E6] to-[#42EBD5] h-[2px] w-full"></div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Faq>

      {[
        "top-0 left-0 w-10 h-full -ml-4",
        "top-0 right-0 w-10 -mr-4 h-full",
        // "top-0 left-0 w-full h-10",
        // "bottom-0 left-0 w-full h-10",
      ].map((pos) => (
        <div
          className={`fixed bg-indigo-900 z-10 blur-3xl opacity-80 animate-blur ${pos}`}
        ></div>
      ))}

      <Footer />
    </div>
  );
}

export default Home;

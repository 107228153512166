import styled from "styled-components";
import * as imgs from "imgs";
import { useEffect, useState } from "react";

const ANIMATION_DELAY = -0.2;
const SQUARE_SIZE = 25;
const SQUARE_COUNT = 15;
const FIRST_SQUARE_SIZE = 10;

const Styled = styled.div`
  background: url(${imgs.bgB}) top center no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;

  @keyframes dot-move {
    0%,
    100% {
      transform: scale(4) rotate(0);
    }

    50% {
      transform: scale(6) rotate(720deg);
    }
  }

  .dot-move {
    animation: dot-move 20s ease-in-out infinite;
  }
`;

const Spiral = () => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const ENABLE_MOUSE_MOVE = false;
  const handleMouseMove = (event: any) => {
    setMousePosition({ x: event.clientX || 1, y: event.clientY || 1 });
  };

  useEffect(() => {
    if (!ENABLE_MOUSE_MOVE) return;

    window?.addEventListener("mousemove", handleMouseMove);

    const cleanup = () => {
      window?.removeEventListener("mousemove", handleMouseMove);
    };

    return () => {
      cleanup();
    };
  }, [ENABLE_MOUSE_MOVE]);

  return (
    <Styled className="blur-lg relative">
      <div className="absolute top-0 left-0 w-full h-full bg-black z-10 opacity-50"></div>

      <div
        className="scale-50 xl:scale-100"
        style={{
          position: "absolute",
          left: !mousePosition.x
            ? "50%"
            : `calc(${(mousePosition.x * 2) / 2}px - 0px)`,
          top: !mousePosition.y
            ? "50%"
            : `calc(${(mousePosition.y * 2) / 2}px - 0px)`,
        }}
      >
        {Array.from({ length: SQUARE_COUNT }, (_, i) => (
          <div
            key={i}
            className="absolute m-auto left-0 top-0 right-0 bottom-0 transition-all dot-move border-[1px] border-violet-900 shadow-[0_0px_100px] shadow-black-900"
            style={{
              animationDelay: `${i * ANIMATION_DELAY}s`,
              width: i === 0 ? FIRST_SQUARE_SIZE : `${i * SQUARE_SIZE}px`,
              height: i === 0 ? FIRST_SQUARE_SIZE : `${i * SQUARE_SIZE}px`,
              borderTopLeftRadius: "5px",
              borderBottomRightRadius: "5px",
            }}
          ></div>
        ))}
      </div>
    </Styled>
  );
};

export default Spiral;

import * as imgs from "imgs";

type Courses = {
  name: string;
  type: "Curso" | "Auto-ajuda";
  image: string;
}[];

export const courses: Courses = [
  {
    image: imgs.img01,
    name: "Hipnose regressiva",
    type: "Curso",
  },
  {
    image: imgs.img02,
    name: "Hipnose não verbal",
    type: "Auto-ajuda",
  },
  {
    image: imgs.img03,
    name: "Hipnose Científica",
    type: "Curso",
  },
  {
    image: imgs.img04,
    name: "Imersão em controle da dor",
    type: "Curso",
  },
  {
    image: imgs.img05,
    name: "Como tratar vícios",
    type: "Curso",
  },
  {
    image: imgs.img06,
    name: "Terapia online",
    type: "Curso",
  },
  {
    image: imgs.img07,
    name: "Controle de Ansiedade",
    type: "Curso",
  },
  {
    image: imgs.img08,
    name: "Aprenda Hipnose  do zero",
    type: "Curso",
  },
  {
    image: imgs.img09,
    name: "Hipnose Clínica",
    type: "Curso",
  },
  {
    image: imgs.img10,
    name: "Induções rápidas e instantâneas",
    type: "Curso",
  },
  {
    image: imgs.img11,
    name: "Super Memória",
    type: "Curso",
  },
  {
    image: imgs.img12,
    name: "Auto-hipnose",
    type: "Curso",
  },
];

export const details: {
  name: string;
  description: string;
  image: string;
}[] = [
  {
    image: imgs.details01,
    name: "+300h",
    description: "O melhor custo-benefício do mercado.",
  },
  {
    image: imgs.details02,
    name: "Live",
    description: "Aulas ao vivo toda semana.",
  },
  {
    image: imgs.details03,
    name: "9.8/10",
    description: "Avaliação do HypnosisLab segundo os clientes.",
  },
  {
    image: imgs.details04,
    name: "Materiais",
    description: "Transcrições e exercícios práticos.",
  },
  {
    image: imgs.details05,
    name: "Offline",
    description: "Reveja as aulas quantas vezes quiser.",
  },
  {
    image: imgs.details06,
    name: "7 dias",
    description: "Acesse agora sem compromisso!",
  },
];

export const testimonials = [
  {
    text: `“Hoje, através dos cursos do Alberto, sou um profissional
    muito melhor. [...] Ele me auxiliou demais nos
    atendimentos que eu faço, tendo resultados muito
    melhores.”`,
    name: "José Renato Martinelli",
    occupation: "Hipnoterapeuta",
  },
  {
    text: `“Foi o maior e mais valioso investimento que eu já fiz. [...] Me deixou completamente preparado para trabalhar na área clínica.”`,
    name: "Leandro Borges",
    occupation: "Hipnoterapeuta",
  },
  {
    text: `“Todo profissional deveria saber essa ferramenta [a Hipnose], porque melhora e muito a sua atuação, seus resultados. Seja psicólogo, fisioterapeuta ou médico, principalmente médico.”`,
    name: "William Soares",
    occupation: "Médico",
  },
];

export const plans = [
 /* {
    name: "MENSAL",
    installments: "12x de",
    price: "R$97,14",
    fullPrice: "R$997,00 à vista",
    benefits: [
      " Certificados de Conclusão",
      "10 treinamentos completos",
      "1 ano de acesso",
      "Suporte online ao aluno",
    ],
    link: "https://google.com.br",
  },*/
  {
    name: "ANUAL",
    installments: "12x de",
    price: "R$145,85",
    fullPrice: "R$1497,00 à vista",
    benefits: [
      " Certificados de Conclusão",
      "+15 treinamentos completos",
      "12 meses de acesso liberado",
      "Suporte online ao aluno",
    ],
    link: "https://pay.hotmart.com/N68518367W?checkoutMode=10",
  },
];

export const faq = [
  {
    question: "Não conheço nada de Hipnose, o Hypnosis Lab é para mim?",
    answer:
      "Sim! Esta plataforma foi pensada tanto para você que já trabalha com a Hipnose, como também para quem está começando agora. Nossa metodologia e nossas aulas vão atualizar quem já conhece o assunto, mas também funcionam como uma introdução às técnicas hipnóticas.",
  },
  {
    question: "Não sou psicólogo e nem terapeuta. Eu preciso desse curso?",
    answer:
      "Sim. Se você é da área da saúde, você precisa! Não é à toa que a maioria dos Conselhos Federais brasileiros aprovam e recomendam o uso da Hipnose. Aqui você vai conhecer as técnicas mais eficazes da hipnose e entender como adaptá-la e colocá-la em prática seja você profissional de qualquer área da saúde.",
  },
  {
    question: "Qual vai ser o valor investido?",
    answer:
      "Seu acesso à plataforma vai custar apenas 12 x de R$97,14* ou R$997,00 à vista.",
  },
  {
    question: "Posso pagar no cartão de crédito ou boleto?",
    answer:
      "Sim! Você terá a oportunidade de fazer o pagamento em boleto, cartão de crédito e cartão de débito na plataforma da Hotmart. ",
  },
  {
    question: "Quanto tempo de acesso terei ao curso?",
    answer:
      "Trata-se de uma assinatura anual, que deverá ser renovada a cada ano. Teremos conteúdos exclusivos sobre Hipnose constantemente renovados, para manter você sempre atualizado.",
  },
  {
    question: "Tenho dúvidas sobre o conteúdo. Posso entrar em contato?",
    answer:
      "Para tirar dúvidas sobre conteúdo, pagamentos e reembolsos entre em contato com nossa equipe pelo e-mail contato@institutodellisola.com ou através do WhatsApp clicando no ícone no canto da tela.",
  },
];

import * as imgs from "imgs";
import Anchor from "./Anchor";

export const Footer = () => {
  return (
    <div className="flex flex-col lg:w-2/3 mx-auto px-4 py-10 lg:py-24 gap-10 lg:gap-20 items-center">
      <div className="bg-[#161E5D] px-6 lg:px-12 py-6 lg:py-10 flex flex-col lg:flex-row rounded-xl items-center content-between gap-4 lg:gap-10 xl:gap-20">
        <div className="">
          <h4 className="text-white text-xl xl:text-2xl text-center lg:text-left">
            Deixe que a Hipnose transforme sua{" "}
            <br className="hidden xl:block" /> carreira e revolucione seus
            atendimentos
          </h4>
        </div>
        <div className="flex justify-end">
          <Anchor href="#plans">Assinar agora</Anchor>
        </div>
      </div>
      <div className="scale-75 lg:scale-100">
        <imgs.logoFooter />
      </div>
      <div className="text-white flex gap-4">
        <a href="#">Termos de Uso</a>|<a href="#">Privacidade</a>
      </div>
    </div>
  );
};

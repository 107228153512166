const Anchor = ({
  children,
  href,
  variant = "primary",
  size = "base",
  ...props
}: JSX.IntrinsicElements["a"] & {
  href: string;
  size?: "base" | "medium";
  variant?: "primary" | "secondary";
}) => {
  const sizes = {
    base: "text-base font-bold px-8 py-4",
    medium: "text-lg lg:text-xl font-semibold px-12 py-5",
  };

  const variants = {
    primary:
      "from-[#4776E6] to-[#42EBD5] before:from-[#ca13d7] before:to-[#4776E6]",
    secondary:
      "from-[#ca13d7] to-[#4776E6] before:from-[#6d0274] before:to-[#063194]",
  };

  const smoothScroll = (e: any) => {
    e.preventDefault();
    const target = e.target.getAttribute("href");
    const offsetTop = document.querySelector(target)?.offsetTop;
    window.scrollTo({
      top: offsetTop,
      behavior: "smooth",
    });
  };

  return (
    <a
      onClick={href.startsWith("#") ? smoothScroll : undefined}
      {...props}
      href={href}
      className={`text-white
    whitespace-nowrap
      rounded-lg
      inline-flex
      overflow-hidden
      bg-gradient-to-r
      transition-all
      hover:bg-transparent
      relative
      z-10
      hover:scale-110
      before:absolute
      before:left-0
      before:top-0
      before:w-full
      before:h-full
      before:bg-gradient-to-r
      before:z-[-1]
      before:transition-all
      before:opacity-0
      before:duration-300
      hover:before:opacity-100
      ${sizes[size]}
      ${variants[variant]}
     ${props.className}`}
    >
      {children}
    </a>
  );
};

export default Anchor;
